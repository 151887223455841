//144112 [HyundaiMotorNetherlands] Add Dealer number in My Account page.
import { connect } from 'react-redux';
import { useSimpleTexts } from 'components/sanaText';
import PropTypes from 'prop-types';
import { ReadOnlyFormGroup } from '../formGroups';

const DealerNumber = ({ dealerNumber }) => {
  const [dealerNumberText] = useSimpleTexts(['DealerNumberText']).texts;
  return (
    dealerNumber &&
    <ReadOnlyFormGroup
      fieldName={dealerNumber}
      fieldTitle={dealerNumberText}
      displayValue={dealerNumber}
    />
  );
};

DealerNumber.propTypes = {
  dealerNumber: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  dealerNumber: user.customer?.id,
});

export default connect(mapStateToProps)(DealerNumber);
